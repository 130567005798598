import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { AuthRedirectService, AuthService, WindowRef } from '@spartacus/core';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UserActivityService } from 'src/app/services/user-activity/user-activity.service';
import { ModalOptions } from '../shared/model/common.mode';
import { UserDetails } from '../shared/model/user.model';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss'],
})
export class UserActivityComponent implements OnInit, OnChanges, OnDestroy {
  @Input() clearTheSession;
  private userActivity;
  private isLogin = false;
  sessionData: UserDetails;
  sessionTimeOut: number;
  userName: string;
  private userInactive: Subject<any> = new Subject();
  private destroy$ = new Subject<void>();
  public modalOptions = {
    open: false,
    header: true,
    modalTitle: '',
    class: 'modal-md',
    width: '460px',
    borderRadius: '20px',
  } as ModalOptions;
  constructor(
    private router: Router,
    private userActivityService: UserActivityService,
    private authService: AuthService,
    private csAgentAuthService: CsAgentAuthService,
    private authRedirectService: AuthRedirectService,
    protected winRef: WindowRef
  ) {
    this.userInactive.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.router.navigate(['/logout-alcon']);
      this.modalOptions.open = true;
      this.winRef.document.body.classList.add('modal-open');
    });
  }

  closeModal(): void {
    this.modalOptions.open = false;
    this.winRef.document.body.classList.remove('modal-open');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setTimeoutForUserActivity();
  }
  ngOnInit(): void {
    combineLatest([
      this.authService.isUserLoggedIn().pipe(distinctUntilChanged()),
      this.userActivityService.getuserSessionData(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isUserLoggedIn, sessionData]) => {
        if (sessionData) {
          const { sessionTimeOut = 0, name = this.userName } = sessionData;
          this.isLogin = isUserLoggedIn && sessionTimeOut !== 0;
          this.sessionTimeOut = sessionTimeOut;
          this.userName = name;
          this.setTimeoutForUserActivity();
        }
      });

    // workaround for ASM redirect
    this.csAgentAuthService
      .isCustomerEmulated()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.userActivityService.setisASM(res);
          this.authRedirectService.redirect();
        }
      });
  }

  private setTimeoutForUserActivity(): void {
    if (this.userActivity) {
      clearTimeout(this.userActivity);
    }
    if (this.isLogin) {
      this.userActivity = setTimeout(() => this.userInactive.next(undefined), this.sessionTimeOut);
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
